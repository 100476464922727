.bb-grid-toolbar-container {
    .toolbar {
        .bb-grid-toolbar-btn {
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px 0 0;
        }

        .bb-column-picker-btn, .bb-filter-btn {
            margin-right: 0;
            margin-left: 5px;
            .btn {
                margin: 0;
            }
        }
    }
}

.bb-grid-toolbar-container {
    .toolbar {
        &.bb-table-toolbar {
            height: auto;
            min-height: 39px;
        }
    }
}

.bb-grid-toolbar-button-container {
    display: inline-block;
    vertical-align: middle;
}

.bb-grid-toolbar-sort-container {
    display: inline-block;
    margin-left: $margin-half;
}

.bb-grid-filter-summary-container {
    .bb-filter-summary {
        border-top: none;
    }
}

/* legacy grid styles */
.toolbar {
    &.bb-table-toolbar {
        height: 39px;
        border: 1px solid $gray-lighter;
        border-right: none;
        border-left: none;
        padding: $padding-half $padding $padding-half $padding;

        &.bb-applied-filter-bar {
            border-top: none;
            display: flex;

            .bb-applied-filter-header {
                margin-right: 10px;
                margin-top: 5px;
                font-weight: 600;
            }
            .bb-applied-filter-content-container {
              flex-basis: 95%;
              min-width: 0;

              .bb-applied-filter-content {
                  background-color: $alert-warning-bg;
                  border: 1px solid $alert-warning-border;
                  border-radius: 4px;
                  padding: 3px;
                  height: 26px;
                  display: inline-block;
                  max-width: 100%;

                  .bb-applied-filter-text {
                      @include text-overflow();
                      color: $alert-warning-text;
                      display: inline-block;
                      padding-right: $padding;
                      cursor: pointer;
                      max-width: 99%;

                      @media (max-width: ($screen-md-max)) {
                        max-width: 98%;
                      }
                      @media (max-width: ($screen-xs-max)) {
                        max-width: 96%;
                      }
                  }

                  .close {
                      color: $alert-warning-text;
                      padding-top: 3px;
                      float: right;
                  }
              }
              .bb-applied-filter-content.bb-applied-filter-content-no-dismiss {
                 .bb-applied-filter-text {

                      max-width: 100%;
                      padding-right: 0;

                      @media (max-width: ($screen-md-max)) {
                        max-width: 100%;
                      }
                      @media (max-width: ($screen-xs-max)) {
                        max-width: 100%;
                      }
                  }
                 
                  .close {
                      display: none;
                  }
              }

              
            }

        }

        .bb-search-container {
            background-color: white;
            display: inline-block;
            vertical-align: middle;

            input {
                float: left;
                height: 32px;
                width: 150px;
                border-radius: 6px 0 0 6px;
                outline: none;
                border: 1px solid $gray-lighter;
                border-right: none;
                -webkit-appearance: none;

                @media (min-width: ($screen-sm-min)) {
                    width: 255px;
                }
            }

            > .bb-search-icon {
                float: left;
                width: 23px;
                height: 32px;
                padding-top: 1px;
                border-radius: 0 6px 6px 0;
                border: 1px solid $gray-lighter;
                border-left: none;
                cursor: default;
                background-color: #fff;
                padding-top: 8px;
            }

            input:focus {
                box-shadow: 3px 0 8px 0 $bb-focus-shadow-color;
                border: 1px solid $bb-focus-border-color;
                border-right: none;
            }

            input:focus + .bb-search-icon {
                box-shadow: 4px 0 6px -1px $bb-focus-shadow-color;
                border: 1px solid $bb-focus-border-color;
                border-left: none;
            }
        }

        .bb-toolbar-btn-label {
            display: none;
            margin-left: 5px;

            @media (min-width: ($screen-md-min)) {
                display: inline;
            }
        }

        .btn-success, .btn-primary {
            .bb-toolbar-btn-label {
                font-family: $font-family-base;
                font-weight: 600;
            }
        }
    }
}
