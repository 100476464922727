@import "Bootstrap/variables";
@import "mixins";


.bb-omnibar {
    .search {
        font-family: $font-family-base;

        > .searchContainer > input.searchbox:focus {
            box-shadow: none;
        }
        
        > .searchContainer > input.searchbox:focus + .searchButton {
            box-shadow: none;
        }
    }

    .desktop, .mobile {
        > div {
            z-index: $omnibar-zindex;
        }
    }
}

/* Help widget */
.bb-help-container {
    z-index: $help-widget-zindex;
}

/* End help widget*/
