@font-face {
  font-family: 'Blackbaud Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans.woff) format('woff');
}

// @font-face {
//   font-family: 'Blackbaud Sans';
//   font-style: normal;
//   font-weight: 500;
//   src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-semi-bold.woff) format('woff');
// }

// @font-face {
//   font-family: 'Blackbaud Sans';
//   font-style: italic;
//   font-weight: 500;
//   src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-semi-bold-italic.woff) format('woff');
// }

@font-face {
  font-family: 'Blackbaud Sans';
  font-style: normal;
  font-weight: 600;
  src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-bold.woff) format('woff');
}

// @font-face {
//   font-family: 'Blackbaud Sans';
//   font-style: italic;
//   font-weight: 600;
//   src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-bold-italic.woff) format('woff');
// }


@font-face {
  font-family: 'Blackbaud Sans';
  font-style: italic;
  font-weight: 400;
  src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-italic.woff) format('woff');
}

@font-face {
  font-family: 'Blackbaud Sans';
  font-style: normal;
  font-weight: 300;
  src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-light.woff) format('woff');
}

// @font-face {
//   font-family: 'Blackbaud Sans';
//   font-style: italic;
//   font-weight: 300;
//   src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-light-italic.woff) format('woff');
// }

// @font-face {
//   font-family: 'Blackbaud Sans';
//   font-weight: 100;
//   src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-thin.woff) format('woff');
// }

// @font-face {
//   font-family: 'Blackbaud Sans';
//   font-style: italic;
//   font-weight: 100;
//   src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-thin-italic.woff) format('woff');
// }

// @font-face {
//   font-family: 'Blackbaud Sans Condensed';
//   font-style: normal;
//   font-weight: 400;
//   src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-condensed.woff) format('woff');
// }

@font-face {
  font-family: 'Blackbaud Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-condensed-light.woff) format('woff');
}


@font-face {
 font-family: 'Blackbaud Sans Condensed';
 font-style: normal;
 font-weight: 500;
 src: url(https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-condensed-semi-bold.woff) format('woff');
}
