@import "mixins";

.bb-check-wrapper input {
    opacity: 0;
    position: absolute;
    height: 22px;
    width: 22px;
    margin: 0;
    padding: 0;
}

.bb-check-wrapper {
  span.bb-check-label-text {
    vertical-align: middle;
    font-weight: 400;
  }
}

.bb-check-checkbox,
.bb-check-radio {
    border: 1px solid $gray-lighter;
    cursor: pointer;
    display: inline-block;
    height: 22px;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    width: 22px;
    text-align: center;
    font-size: 14px;
    background-color: $bb-color-white;
    line-height: 23px;
}

.bb-check-radio {
    border-radius: 50%;
}

.bb-check-wrapper input:hover + .bb-check-checkbox,
.bb-check-wrapper input:hover + .bb-check-radio {
    border: 2px solid $bb-color-blue;
}

.bb-check-wrapper input:checked + .bb-check-checkbox,
.bb-check-wrapper input:checked  + .bb-check-radio {
    background-color: $bb-color-blue;
    border: none;

    &:before {
      color: $bb-color-white;
      content: $bbsky-char-check-nostroke;
      font-family: BBSKY;
      font-size: $bb-font-size-sm;
    }
}


.bb-check-wrapper input:disabled + .bb-check-checkbox,
.bb-check-wrapper input:disabled + .bb-check-radio {
    background-color: $input-bg-disabled;
    border: 1px solid $gray-lighter;
    cursor: default;
}

.bb-check-wrapper input:focus + .bb-check-checkbox,
.bb-check-wrapper input:focus + .bb-check-radio {
    @include bb-focus-indication();
}

@media (min-width: $screen-sm-min) {
    .form-inline {
        .bb-check-wrapper {
            margin-bottom: 0;
        }
    }
}