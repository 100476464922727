$sky-color-white: #ffffff !default;
$sky-color-black: #000000 !default;
$sky-color-gray-10: #e2e3e4 !default;
$sky-color-gray-20: #cdcfd2 !default;
$sky-color-gray-70: #686c73 !default;
$sky-color-gray-80: #4d525a !default;
$sky-color-gray-90: #393d44 !default;
$sky-color-gray-100: #282b31 !default;
$sky-color-gray-01: #fbfbfb !default;
$sky-color-gray-05: #eeeeef !default;
$sky-color-blue-10: #c1e8fb !default;
$sky-color-blue-30: #81d4f7 !default;
$sky-color-blue-50: #00b4f1 !default;
$sky-color-blue-70: #007ca6 !default;
$sky-color-green-30: #b7da9b !default;
$sky-color-green-50: #71bf43 !default;
$sky-color-yellow-30: #ffd597 !default;
$sky-color-yellow-50: #fbb034 !default;
$sky-color-red-30: #f7a08f !default;
$sky-color-red-50: #ef4044 !default;
$sky-color-red-70: #d93a3d !default;
$sky-color-purple-10: #f1eef6 !default;
$sky-color-purple-50: #936daf !default;
$sky-color-teal-50: #00b795 !default;
$sky-color-teal-70: #00866d !default;
$sky-text-color-default: #282b31 !default;
$sky-text-color-deemphasized: #686c73 !default;
$sky-text-color-on-dark: #ffffff !default;
$sky-text-color-action-primary: #007ca6 !default;
$sky-text-color-icon-borderless: #cdcfd2 !default;
$sky-background-color-neutral-light: #eeeeef !default;
$sky-background-color-primary-dark: #007ca6 !default;
$sky-background-color-selected: #f1eef6 !default;
$sky-background-color-disabled: #cdcfd2 !default;
$sky-background-color-info: #81d4f7 !default;
$sky-background-color-info-light: #c1e8fb !default;
$sky-background-color-success: #b7da9b !default;
$sky-background-color-warning: #ffd597 !default;
$sky-background-color-danger: #f7a08f !default;
$sky-background-color-danger-dark: #d93a3d !default;
$sky-highlight-color-info: #00b4f1 !default;
$sky-highlight-color-success: #71bf43 !default;
$sky-highlight-color-warning: #fbb034 !default;
$sky-highlight-color-danger: #ef4044 !default;
$sky-border-color-neutral-light: #e2e3e4 !default;
$sky-border-color-neutral-medium: #cdcfd2 !default;
