@import "mixins";

.bb-checklist-wrapper {
    overflow-y: auto;
    min-height: 75px;

    table {
        margin: 0;

        thead {
            th:first-child, th:last-child {
                @include rounded-corners(0, 0, 0, 0);
            }
        }
    }
}

.bb-checklist-table {
    border-top: 1px solid $gray-lighter;
}

.bb-checklist-checkbox-column {
    width: 30px;
}

.bb-checklist-row {
    cursor: pointer;
}

.bb-checklist-filter-bar {
    background-color: $sky-background-color-neutral-light;
    border-bottom: 1px solid $gray-lighter;
    padding: $padding;

    &:last-child {
        border: none;
    }

    .btn {
        margin: 0 $margin-half;
        min-width: 65px;

        &.btn-link {
            margin: 0 $margin-half 0 0;
            min-width: 0;
            outline: none;
        }
    }

    .bb-checklist-clear {
        margin-right: 15px;
    }

    .btn-primary {
        font-size: 12px;
        font-weight: normal;
    }

    input.bb-checklist-search-box {
        background-color: #fff;
        width: 100%;
        outline: none;
        border: none;
        -webkit-appearance: none;
        height: auto;
        box-shadow: none;

    }

    .bb-search-disabled {
      .bb-checklist-search-box, .bb-checklist-search-icon {
          background-color: $input-bg-disabled;
          cursor: not-allowed;
      }
    }
}

.bb-checklist-select-all-bar {
    padding-bottom: $padding-half;
    padding-top: $padding-half;
}

.bb-checklist-category-bar {
    white-space: nowrap;
    overflow: auto;
    .form-control {
        margin-right: $margin-plus-half;
    }
    .control-label {
        margin-bottom: 0;
    }
    .form-group {
        margin-bottom: 0;
    }

    @media (max-width: $screen-sm-max) {
      .form-group + .form-group {
          margin-bottom: 0;
          margin-top: 5px;
      }
    }
}

.bb-checklist-search {
    display: flex;
    display: -ms-flex;
    width: 100%;
    height: 26px;
}

.bb-checklist-search-icon {
    background-color: #fff;
    width: 22px;
}

.bb-checklist-link {
    margin-right: $margin;
}

.bb-checklist-no-items {
    text-align: center;
    margin-top: $margin;
    margin-bottom: $margin;
}

.bb-checklist-list-row {
    background-color: transparent;
    border: none;
    border-bottom: dotted 1px $gray-lighter;
    cursor: pointer;
    display: table;
    margin-bottom: 0;
    padding: $padding;
    outline-offset: -5px;
    table-layout: fixed;
    text-align: left;
    width: 100%;

    &:last-child {
        border: none;
    }
}

.bb-checklist-list-col {
    display: table-cell;
    font-weight: normal;
    padding-right: $padding;
    vertical-align: middle;
    word-wrap: break-word;
}

.bb-checklist-list-col-checkbox {
    width: $padding + 22px;
}

.bb-checklist-list-title {
    @include bb-emphasized();
}

.bb-checklist-single {
    .bb-checklist-row-selected {
        background-color:#eeeffb; //TODO: Figure out if this needs to go into a variable
    }

    .bb-checklist-list-col-checkbox {
        //display: none;
    }
}
