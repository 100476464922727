@import "mixins";

.bb-filters-inline {
    background-color: $bb-inline-filter-color;
    padding: $padding;
}

.toolbar.bb-table-toolbar .bb-filter-btn.bb-filters-inline-active, .bb-filter-btn-active, .bb-filter-btn-active:hover, .bb-filter-btn-active:focus {
    color: $bb-color-green;
    border: 2px solid $bb-color-green;
    border-radius: 3px;
    padding: 5px 11px; /* offset the thicker border to avoid button size change */
}

.toolbar.bb-table-toolbar .bb-filter-btn.bb-filters-inline-active:hover, .bb-filter-btn-active:hover, .bb-filter-btn-active:focus {
    color: $text-color;
}

.bb-filter-summary {
    background-color: $bb-color-white;
    min-height: 39px;
    overflow-x: auto; 
    display: flex;
    align-items: center;
    @include bb-border(dark, top, bottom);
    padding: $padding-half $padding $padding-half $padding; 
}

.bb-filter-summary-items {
    white-space: nowrap;
}

.bb-filter-summary-item {
    display: inline-block;
    background-color: $sky-background-color-info-light;
    border: 1px solid $sky-highlight-color-info;
    border-radius: 4px;
    padding: 3px;
    color: $sky-text-color-default;
    margin-right: $margin-half;

    .close {
        color: $sky-text-color-default;
        padding-left: $padding-half;
        float: none;
        opacity: 0.8;
        text-shadow: none;
      
      &:hover, &:focus {
        opacity: 1.0;
      }
      
    }
}

.bb-filter-summary-header {
    margin-right: $margin;
    font-weight: 600;
}
