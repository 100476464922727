@import "mixins";

.bb-select-field-single {
    width: 100%;
}

bb-select-field.ng-invalid.ng-touched .bb-select-field-single {
  @include field-invalid;
}

.bb-select-field-single-inner {
    // The select field "inner" class is needed because setting display: flex on a button element doesn't work
    // as expected in Firefox.  Therefore we need a div inside the button that has this attribute.
    // http://stackoverflow.com/questions/24289251/flexbox-not-working-in-buttons-in-firefox
    width: 100%;
    display: flex;
    align-items: center;
}

.bb-select-field-single-title {
    flex: 1;
    text-align: left;
    white-space: normal;
}

.bb-select-field-single-title-placeholder {
  @include bb-deemphasized();
}

.bb-select-field-single-icon {
    width: 18px;
    flex: 0 0 18px;
    text-align: right;
}

.bb-select-field-multiple {
  display: block;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

.bb-select-field-multiple-items {
    margin-left: $margin-plus-half;
}

.bb-select-field-multiple-item {
    background-color: $sky-background-color-info-light;
    border: solid 1px $sky-highlight-color-info;
    border-radius: 4px;
    padding: 0 0 0 $padding;
    margin-top: $margin;
    display: inline-flex;
    align-items: center;
    margin-right: $margin;
}

.bb-select-field-multiple-item-title {
    flex-shrink: 0;
}

.bb-select-field-multiple-item-delete {
    color: change-color($sky-text-color-default, $alpha: 0.8);
    text-shadow: none;
    height: 34px;
    width: 34px;
    flex: 0 0 34px;
    opacity: 1;
}

.bb-select-field-multiple-item-delete:hover {
    opacity: 1;
    color: $sky-text-color-default;
}

.bb-select-field-multiple-summary {
    font-style: italic;
}
