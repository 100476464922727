@import "mixins";

/* NAVIGATION START */

.nav > li > a i {
  margin-right: 6px;
}

.navbar {
    border: 0;
    border-radius: 0;
}

.bb-navbar {
    @include bb-border(dark);
    background-color: $bb-nav-bg-color;
    margin-bottom: 0;

    @media (max-width: $screen-xs-max) {
        &:not(.bb-navbar-showmobile) {
            display: none;
        }
    }

    .dropdown .dropdown-menu {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.navbar {
        border-radius: 0;
    }

    .nav.navbar-nav {
        font-family: $bb-font-family-alternate;
        padding: 0;
        margin: 0;
        font-size: 0;
        color: $bb-nav-color;

        > li {
            cursor: pointer;
            font-size: 14px;
            white-space: nowrap;
            margin-right: $margin-half;

            .dropdown-menu {
                li {
                    margin: 0;

                    a {
                        background-color: $bb-nav-bg-color;
                        border-bottom: none;
                        font-size: 13px;
                        font-weight: normal;
                        color: $bb-nav-color;

                        padding: $padding $bb-nav-item-padding *0.75;
                        text-decoration: none;
                        margin: 0;
                        border-radius: 0;

                        &:hover {
                            background-color: $bb-nav-selected-bg-color;
                            color: $bb-nav-selected-color;
                        }
                    }
                }
                li.bb-navbar-active a {
                    background-color: $bb-nav-selected-bg-color;
                    color: $bb-nav-selected-color;
                }
            }

            > a {
                padding: $bb-nav-item-padding $bb-nav-item-padding * 0.75;
                text-decoration: none;
                color: $bb-nav-color;
                font-weight: 600;
                margin: 0;
                border-radius: 0;

                &:hover {
                    background-color: $bb-nav-selected-bg-color;
                    color: $bb-nav-selected-color;
                }
            }


            &:hover,
            &.bb-navbar-active {
                a {
                    background-color: $bb-nav-selected-bg-color;
                    @include bb-border(nav);
                    color: $bb-nav-selected-color;
                    padding-bottom: $bb-nav-item-padding - $bb-nav-selected-border-width;
                }
            }

            &.disabled {
                pointer-events: none;

                a {
                    background-color: transparent;
                    border-bottom: none;
                    cursor: default;
                    color: darken($bb-nav-color, 20%);
                    padding-bottom: 0;
                }
            }
        }

        &.navbar-right {
            li {
                margin-left: $margin-half;
                margin-right: 0;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    ul.nav-items {
        li {
            a {
                padding: $bb-nav-item-padding;
            }

            &:after {
                left: ($margin + $padding-half);
            }
        }
    }
}

.bb-navbar-showmobile {
    .bb-navbar {
         @media (max-width: $screen-xs-max) {
            display: block;
         }
    }
}


/* NAVIGATION END */
