@import "variables";
@import "bootstrap-variable-defaults";
@import "Bootstrap/variables";
@import "compass";
@import "animation/core";
@import "Bootstrap/mixins";

@mixin shadow ($h-shadow:0px, $v-shadow:0px, $blur:5px, $spread-radius:0, $color:$gray-dark-text) {
    box-shadow: $h-shadow $v-shadow $blur $spread-radius $color;
}

@mixin rounded-corners ($tl-length:5px, $tr-length:5px, $br-length:5px, $bl-length:5px) {
    border-radius: $tl-length $tr-length $br-length $bl-length;
}

@mixin text-shadow ($h-shadow:1px, $v-shadow:1px, $blur:0px, $color:$gray) {
    text-shadow: $h-shadow $v-shadow $blur $color;
}

@mixin field-invalid {
    box-shadow: 0 0 8px rgba($sky-highlight-color-danger, 0.6);
    border: 1px solid $sky-highlight-color-danger;
    outline: none;
}

@mixin input-group-field {
    margin-bottom: 21px;
}

@mixin input-group-field-invalid {
    margin-bottom: 1px;
}

@mixin spin ($speed: 1s) {
    -webkit-animation: bb-spin-frames $speed infinite linear;
    -moz-animation: bb-spin-frames $speed infinite linear;
    -o-animation: bb-spin-frames $speed infinite linear;
    animation: bb-spin-frames $speed infinite linear;
    /* Make the element inline-block so that it spins in place. */
    display: inline-block;
}

@mixin bb-border($type, $sides...) {

    @if(length($sides) == 0) {
        @if($type != "tile") {
            $sides: "bottom";
        } @else {
            $sides: "top";
        }
    }

    @each $side in $sides {
      @if($type == "light") {
          border-#{$side}: 1px solid $sky-border-color-neutral-light;
      }

      @if($type == "dark") {
          border-#{$side}: 1px solid $sky-border-color-neutral-medium;
      }

      @if($type == "nav") {
          border-#{$side}: $bb-nav-selected-border-width solid #1c84c6;
      }

      @if($type == "tab") {
          border-#{$side}: 2px solid $sky-background-color-primary-dark;
      }

      @if($type == "tile") {
          border-top: 4px solid $sky-border-color-neutral-medium;
      }

      @if($type == "row") {
          border-#{$side}: 1px dotted $sky-border-color-neutral-medium;
      }
    }


}

@mixin bb-page-heading {
    font: 300 34px $bb-font-family-narrow;
    color: $text-color;
}

@mixin bb-subsection-heading {
    font: 300 19px $font-family-base;
    color: $bb-text-deemphasized-color;
}

@mixin bb-section-heading {
    font: 300 26px $bb-font-family-narrow;
    color: $text-color;
}

@mixin bb-headline {
    font: 500 22px $bb-font-family-narrow;
    color: $text-color;
}

@mixin bb-emphasized {
    font: 600 16px $font-family-base;
    color: $text-color;
}

@mixin bb-field-label {
    font: 400 $font-size-base $font-family-base;
    color: $bb-text-deemphasized-color;
}

@mixin bb-deemphasized {
    font: 400 italic $font-size-base $font-family-base;
    color: $bb-text-deemphasized-color;
}

@mixin bb-text-error {
    color: $sky-text-color-default;

    &::before {
    content: "\f071";
	font-family: FontAwesome;
	margin-right: 5px;
	color: $sky-highlight-color-danger;
    }
}

@mixin bb-icon-button-borderless {
    color: $sky-text-color-icon-borderless;
    cursor: pointer;
    
    &:hover, &:focus {
        color: darken($sky-text-color-icon-borderless, 20%);
    }
}

@mixin bb-focus-indication {
    outline: thin dotted;

    //webkit
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;
}