@import "mixins";

.tab-pane {
    background-color: $bb-color-white;
}

.nav.nav-tabs {
    padding-left: 15px;
}

.nav-tabs > li > a {
    padding: 7px 15px;
}

.bb-tabset-dropdown {
    @include bb-border(tab);
    .bb-tab-dropdown-button {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: -1px;
        .fa-caret-down {
            margin-left: 5px;
        }
    }
}

.nav.nav-tabs, .nav-tabs.nav-justified {
    @include bb-border(tab);

    > li {
        margin-top: 5px;
    }

    > li.bb-tab-close {
        > a {
            padding-right: 5px;
        }
    }

    > li.bb-tab-button {
        .bb-tab-button-wrap {
            background-color: transparent;
            border: none;
            padding: 0px 0px;
            width: 44px;
            .bb-btn-secondary {
                color: $bb-color-blue;
                padding-top: 5px;
                padding-bottom: 5px;
            }

        }
        .bb-tab-button-wrap:active, .bb-tab-button-wrap:hover, .bb-tab-button-wrap:focus {
            box-shadow: none;
            outline: none;
            .bb-btn-secondary {
                @include bb-focus-indication();
            }
        }
        .bb-tab-button-add {
            .bb-btn-secondary {
                padding-left: 7px;
                padding-right: 7px;
            }
        }
        .bb-tab-button-open {
            .bb-btn-secondary {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }

    .bb-tab-button-wrap {
        background-color: transparent;
        border: none;
        padding: 0px 0px;
        width: 44px;

        .bb-btn-secondary {
            color: $bb-color-blue;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    .bb-tab-button-wrap:active, .bb-tab-button-wrap:hover, .bb-tab-button-wrap:focus {
        box-shadow: none;
        outline: none;
    }

    .bb-tab-button-add {
        .bb-btn-secondary {
            padding-left: 7px;
            padding-right: 7px;
        }
    }

    .bb-tab-button-open {
        .bb-btn-secondary {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    > li.bb-tab-button {
        border-bottom: none;
        margin-bottom: 0;
        margin-left: 5px;
    }


    > li, ul.dropdown-menu > li {
        > a {
            color: $gray-light;
            font-size: $font-size-base;
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;

            .bb-tab-close-icon {
                padding-left: 10px;
                padding-top: 2px;
                padding-bottom: 3px;
                cursor: pointer;
                -webkit-appearance: none;
                background-color: transparent;
                border: none;
                outline: none;
                @extend .fa;
                @extend .fa-times;
                position: absolute;
                right: 0;
            }

            .bb-tab-close-icon:active,
            .bb-tab-close-icon:hover,
            .bb-tab-close-icon:focus {
                box-shadow: none;
                outline: none;
            }

            .bb-tab-close-icon:focus:before {
                @include bb-focus-indication();
            }

        }

        > a:hover {
            background-color: $gray-lightest;
            border-color: transparent;

            border-bottom-color: $bb-color-blue;
        }

        .bb-tab-header-title {
            margin-top: 0;
        }

        .bb-tab-header-count {
            font-size: $font-size-base;
            font-weight: 400;
            color: $bb-color-blue;
        }
    }

    .bb-tabset-dropdown > ul.dropdown-menu > li {
        > a {
            max-width: 300px;
            padding-right: 5px;
            .bb-tab-close-icon {
                padding-top: 5px;
                float: right;
            }
        }
    }

    > li.active, ul.dropdown-menu > li.active {
        > a,
        > a:hover,
        > a:focus {
            background-color: $bb-color-blue;
            border-color: transparent;
            color: $bb-color-white;
        }

        .bb-tab-header-title,
        .bb-tab-header-count {
            color: $bb-color-white;
        }
    }
}

.bb-tab-close {
    > a {
        tab-heading, uib-tab-heading {
            position: relative;
            padding-right: 30px;
            display: block;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.bb-tab-header-count {
    font-size: $font-size-base;
    font-weight: 400;
    color: $bb-color-blue;
    margin-left: 5px;
}

.bb-tab-header-title {
    color: #808486;
    font-size: $font-size-base;
    font-weight: 600;
    margin-top: 5px;
}

.bb-tab-spacer {
    width: 100px;
    visibility: hidden;
}

.bb-tab-scroll > .nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    li {
        display: inline-block;
        float: none;
    }
}

.bb-tab-header-text {
    max-width: 200px;
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}

@media (min-width: 768px) {
    .nav-justified>li, .nav-tabs.nav-justified>li {
        width: auto;
        >a {
            border: none;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .bb-vertical-tabset {
        .bb-vertical-tabset-tabs {
            @include animation(fadeInLeft 500ms);
            @include make-xs-column(12);
            border-right-style: none;

            &.ng-hide.ng-animate {
                display: none !important;
            }
        }

        .tab-content {
            @include animation(fadeInRight 500ms);
            @include make-xs-column(12);
        }
    }
}

@media (min-width: $screen-sm-min) {
    .bb-vertical-tabset {
        display: flex;

        .bb-vertical-tabset-tabs {
            flex-basis: 25%;
        }

        .tab-content {
            padding-left: $padding-plus-half;
            padding-right: $padding-plus-half;
        }
    }
}

.bb-vertical-tabset {
    .panel-group .panel {
        box-shadow: none;
        width: 100%;

        &.panel-open {
            @include bb-border('light', 'bottom');
        }

        > .panel-heading {
            @include bb-emphasized;
            font-weight: 400;
            @include bb-border('light', 'bottom');
            color: $sky-text-color-default;
            background-color: $bb-color-white;
            padding: 0;
        }
        .panel-heading + .panel-collapse > .panel-body,
        .panel-group .panel-heading+.panel-collapse > .list-group {
            border-top-width: 0;
        }
    }

    .panel-body {
        padding: 0;
    }

    .panel-title {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }

    .bb-vertical-tabset-tabs {
        height: inherit;
        overflow: auto;
        padding-left: 0;
        padding-right: 0;

        .nav.nav-tabs {
            border-bottom-style: none;
            overflow: auto;
            padding-right: 0;

            li {
                display: flex;
                margin-bottom: 0;
                width: 100%;

                & > a {
                    width: 100%;
                }

                .control-label {
                    flex-basis: 100%;
                    flex-grow: 1;
                    min-width: 0;
                    white-space: normal;
                }

                .nav-link {
                    @extend .bb-field-label;
                }

                &.active .nav-link,
                &:hover .nav-link,
                & > a:focus .nav-link {
                    color: $text-color !important;
                }
                &.active .nav-link {
                    // Override uib-tab styling
                    background-color: $gray-lightest !important;
                    font-weight: 600;
                    padding-left: $base-padding - 4px;
                }

                &.active:before {
                    background-color: $bb-color-blue;
                    content: ' ';
                    width: 4px;
                }

                &:last-of-type {
                    margin-bottom: 5px;
                }
            }

            .nav-link {
                border-radius: 0;
                margin-right: 0;
                padding-left: 10px;

                &:focus:not(:hover) {
                    background-color: transparent;
                }

                &:hover {
                    border-bottom-color: transparent;
                }
            }
        }
    }

    .tab-content {
        flex-grow: 1;
        height: inherit;
        margin-top: 0;
        overflow: auto;
        padding-top: $padding;
    }

    .bb-vertical-tabset-group-heading {
        padding: 10px 15px;
    }
  
    .bb-vertical-tabset-group-chevron {
        @extend .fa;
        @extend .fa-chevron-down;

        position: absolute;
        right: 15px;
        transition: all 0.3s linear;
      
        @include bb-icon-button-borderless;

        &.bb-vertical-tabset-group-chevron-open {
            transform:rotate(180deg);
        }
    }

    .bb-vertical-tabset-group {
        border-width: 0;

        .panel-heading:hover .panel-title,
        .bb-vertical-tabset-group-active {
            color: $text-color;
        }
        .panel-heading .panel-title > a:focus .bb-vertical-tabset-group-heading {
            @include bb-focus-indication();
            color: $text-color;
        }
        .bb-vertical-tabset-group-active {
            font-weight: 600;
        }
        a[disabled] {
            cursor: not-allowed;

        .vertical-tabset-group-heading {
            color: $sky-color-gray-20;
        }
      }
    }
}
