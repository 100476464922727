@import "mixins";

.bb-wait-wrap {
    height: 35px;
}

.bb-wait-spinner {
    @include spin(1s);

    &::before {
        content: $bbsky-char-loading;
        font-family: BBSKY;
        font-size: 35px;
        line-height: 35px;
        display: block;
        clear: both;
        width: 35px;
        margin: auto;
        color: black;
    }
}

.bb-wait-nonblock {
    width: 0;
    height: 65px;
    position: fixed;
    bottom: 15px;
    left: 15px;
    opacity: 0.7;
    z-index: 10;
}

/* BlockUI */
.blockUI {
    &.blockOverlay {
        background-color: black;
        cursor: wait;
        opacity: 0.1;
    }

    &.blockMsg {
        @include box-shadow(0 0 50px $gray-light);
        background-color: $gray-lightest;
        padding: $padding-plus-half;
        cursor: wait;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;

        &.blockPage {
            top: 40%;
            left: calc(50% - 33px); // 33px is half the height and width of the block.  Variables can't be used in calc.
        }
    }
}
/* End BlockUI */
