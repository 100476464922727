@import "mixins";

#toast-container {
    .bb-toast {
        color: $sky-text-color-default;
        background-image: none;
        border-radius: $alert-border-radius;
        box-shadow: none;
        display: -webkit-flex; /* iOS 8 support */
        display: flex;
        align-items: center;
        padding: 0;
        background-color: $sky-background-color-info;
        border: none;
      
        a {
          color: change-color($sky-text-color-default, $alpha: 0.8);
          text-decoration: underline;

          &:hover {
            color: $sky-text-color-default;
            text-decoration: underline;
          }
        }
        
        .toast-message {
            border-left: 30px solid $sky-highlight-color-info;
            background-color: $sky-background-color-info;
            padding: $padding;
          
          &::before {
            content: "\f05a";
            font-family: FontAwesome;
            margin-left: -30px;
            margin-right: 19px;
            color: $sky-color-white;
          }
        }

        .toast-close-button {
            background-color: $sky-background-color-info;
            color: change-color($sky-text-color-default, $alpha: 0.8);
            opacity: 1;
            order: 1;
            position: static;
            top: 0;
            right: 0;
            float: none;
            font-size: 24px;
            min-width: 40px;
            text-shadow: none;
            transition: background-color 250ms;
          
          &:hover {
            color: $sky-text-color-default;
          }
        }

        // This is the only thing that identifies the message container
        div {
            width: 100%;
        }

        &.ng-enter {
            @include animation(bounceIn 500ms);
        }

        &.ng-leave {
            @include animation(bounceOut 500ms);
        }
    }

    .bb-toast-success {
        background-color: $sky-background-color-success;
      

        .toast-message {
            background-color: $sky-background-color-success;
            border-color: $sky-highlight-color-success;
          
          &::before {
            content: "\f00c";
            font-family: FontAwesome;
            margin-left: -31px;
            margin-right: 18px;
            color: $sky-color-white;
          }
        }

        .toast-close-button {
            background-color: $sky-background-color-success;
        }
    }

    .bb-toast-warning {
        background-color: $sky-background-color-warning;

        .toast-message {
            border-color: $sky-highlight-color-warning;
            background-color: $sky-background-color-warning;
          
          &::before {
            content: "\f071";
            font-family: FontAwesome;
            margin-left: -31px;
            margin-right: 18px;
            color: $sky-color-white;
          }
        }

        .toast-close-button {
            background-color: $sky-background-color-warning;
        }
    }

    .bb-toast-error {
            background-color: $sky-background-color-danger;

        .toast-message {
            border-color: $sky-highlight-color-danger;
            background-color: $sky-background-color-danger;
          
          &::before {
            content: "\f071";
            font-family: FontAwesome;
            margin-left: -31px;
            margin-right: 18px;
            color: $sky-color-white;
          }
        }

        .toast-close-button {
            background-color: $sky-background-color-danger;
        }
    }
}
