@import "mixins";

.bb-chevron {
    border: none;
    background-color: transparent;
    display: none;
    flex-shrink: 0;
    height: $bb-context-menu-size;
    margin: 0;
    overflow: hidden;
    padding: $padding-half 0 0 0;
    width: $bb-context-menu-size;
}

.bb-chevron-part {
    border-color: #d1dade;
    border-style: solid;
    border-width: 3px 0 0 0;
    display: inline-block;
    height: 10px;
    position: relative;
    top: 3px;
    transition: transform $bb-transition-time-medium, left $bb-transition-time-medium;
    vertical-align: top;
    width: 10px;
}

.bb-chevron-flip-up {
    .bb-chevron-left {
        left: 7px;
        transform: rotate(-45deg);
    }

    .bb-chevron-right {
        left: -6px;
        transform: rotate(45deg);
    }
}

.bb-chevron-flip-down {
    .bb-chevron-left {
        left: 2px;
        transform: rotate(45deg);
    }

    .bb-chevron-right {
        left: -1px;
        transform: rotate(-45deg);
    }
}
