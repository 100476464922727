@import 'mixins';

.bb-key-info-item {
    @include bb-headline();
    margin-bottom: $margin-plus-half;
}

.bb-key-info {
    display: inline-block;
    margin-right: $margin-triple;
}

.bb-key-info-value {
    @include bb-headline();
}

.bb-key-info-label {
    @include bb-field-label();
}

.bb-key-info-horizontal {
    .bb-key-info-value, 
    .bb-key-info-label {
        display: inline-block;  
    }
    
    .bb-key-info-label {
        margin-left: $margin-half;    
    }
}