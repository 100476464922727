@import "mixins";

html, form, body {
    font-size: $font-size-base;
    font-family: $font-family-base;
}

body {
    background-color: $body-bg;
    color: $text-color;
}

/*Headings*/

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 100;
    font-family: $font-family-base;
}

h2,
.h2 {
    font-size: 26px;
}

h3,
.h3 {
    font-size: 19px;
}

h4,
.h4 {
    font-size: 14px;
}

h5,
.h5 {
    font-size: 12px;
}

h6,
.h6 {
    font-size: 10px;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 600;
}

h1,
.h1 {
    font-family: $font-family-base;
    font-size: 34px;
}

h6,
.h6 {
    font-size: 14px;
}

/* Bootstrap class */
.page-header {
    border-bottom: none;
    margin: $margin-double 0;
    padding-bottom: 0;
}

/*End Headings*/

/* Links */
a {
    &.no-pointer {
        pointer-events: none;
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

a.alert-link:hover, a.alert-link:focus {
  text-decoration: underline;
}
/* End Links */

/* Placeholder Text */
::-webkit-input-placeholder {
    @include bb-deemphasized;
    opacity: 1;
}

::-moz-placeholder { /* Firefox 19+ */
    @include bb-deemphasized;
    opacity: 1;
}

input:-moz-placeholder { /* Firefox 18- */
    @include bb-deemphasized;
    opacity: 1;
}

:-ms-input-placeholder { /* IE */
    @include bb-deemphasized;
    opacity: 1;
}
/* End Placeholder Text */

/* Form Elements */
.control-label {
    font-weight: normal;
}

.form-control:focus, .form-control:focus.ng-invalid {
    box-shadow: 0 0 8px $bb-focus-shadow-color;
    border: 1px solid $bb-focus-border-color;
}

.checkbox {
    font-weight: normal;
    line-height: 1.67;
    margin-top: 0;
}

/* End Form Elements */

/* Data Elements */
.bb-no-records {
    color: $gray-light;
    font-style: italic;
    text-align: center;
    font-size: $font-size-h4;
    white-space: pre-line;
}

/* End Data Elements */

//$screen-xs-max
@media (max-width: $screen-xs-max) {
    .bb-page-content-column-container,
    .bb-page-content-column,
    .bb-page-content-tile-column {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    // Since we remove the gutters on the sides of tiles on small screens, we need to remove
    // the right margin and padding from the header so it doesn't create a horizontal scroll.
    .bb-tile .bb-tile-title > .row {
        margin-right: 0;
    }

    .bb-tile-header-column-tools {
        padding-right: 0;
    }
}

.highlight {
    background-color: $sky-highlight-color-warning;
}

.grid-no-search .highlight {
    background-color: initial;
}

/* Labels */

label.error {
    margin-top: 3px;
    margin-bottom: 2px;
    @include bb-text-error;
    font-weight: 400;
}

/* End Labels*/

/* Info Alerts */

.info-alert {
    margin-left: 10px;
    margin-right: 10px;
}

/* End Info Alerts*/

/* Tooltip */

/*overriding default tooltip styling for angular ui bootstrap */
.tooltip-inner {
    background-color: $bb-color-white;

    text-align: left;
    color: $gray-dark;
    font-family: $font-family-base;
    font-size: $font-size-base;
    padding: 10px;
    @include shadow($color:$gray-light);

    .bb-tooltip-title {
        font-weight: bold;
    }

    hr {
        margin: 0;
        padding-bottom: 5px;
    }
}

.tooltip.in {
    opacity: 1;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: $gray-lighter;
    border-width: 0 10px 5px 10px;
}

/* Tooltip End*/

dt {
    font-weight: 600;
}

#page-wrapper {
    margin: 0;
    padding: 0;
    min-height: 0;
}
