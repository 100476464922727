@import "mixins";

.table {
    thead {
        background-color: $gray-lightest;
        border: 0;
        white-space: nowrap;

        tr th {
            font-weight: normal;
            color: grey;
            border: 0;
            border-right: 1px solid $gray-lighter;
        }

        th {
            &.sort-asc, .sort-asc {
                &::after {
                    content: "";
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-bottom: 6px solid $gray;
                    margin-left: 3px;
                    display: inline-block;
                    position: relative;
                    top: -1px;
                }
            }

            &.sort-desc, .sort-desc {
                &::after {
                    content: "";
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 6px solid $gray;
                    margin-left: 3px;
                    display: inline-block;
                    position: relative;
                    top: -1px;
                }
            }
        }

        th:first-child {
            border-radius: 4px 0 0 4px;
            border-left: none;
        }
    }

    th:last-child {
        border-radius: 0 4px 4px 0;
        border-right: none;
    }

    tbody tr td {
        border-top: 0;
        border-bottom: 1px dotted $gray-lighter;
    }

    .table {
      background-color: $bb-color-white;
    }
}

.bb-table-loadmore,
.bb-table-backtotop {
    padding: $padding-half;
    text-align: center;
    border: 1px solid $gray-lighter;
    margin-top: $margin-half;
    cursor: pointer;
    .btn-link {
        font-size: 13px;
        vertical-align: baseline;
    }
}

.bb-table-loadmore {
    background-color: $gray-lightest;
    margin-bottom: $margin-half;
}

.bb-table-backtotop {
    background-color: $toolbar-background-color;
}

.table-responsive {
    border: none;
}
