@import "mixins";

@for $i from 1 through length($bb-palette-multi) {
  .bb-palette-multi-#{$i} {
    background-color: nth($bb-palette-multi, $i);
  }
}

@for $i from 1 through length($bb-palette-mono) {
  .bb-palette-mono-#{$i} {
    background-color: nth($bb-palette-mono, $i);
  }
}
