@import "mixins";

.bb-page-summary-alert .alert {
    margin-top: 0;
    margin-bottom: $margin-double;
    line-height: initial; /*TODO: This needs to come out */
}

.bb-page-summary-title {
    @include bb-page-heading();
    margin-top: 0;
    margin-bottom: $margin-half;
}

.bb-page-summary-subtitle {
    @include bb-emphasized();
    margin-top: 0;
    margin-bottom: $margin;
    line-height: $headings-line-height;
}

.bb-page-summary-status {
    margin-top: $margin;

    .label {
        margin-right: $margin-half;
    }
}

.bb-page-summary-content {
    margin-top: $margin-double;
}

.bb-page-summary-image {
  .bb-file-drop-col {
      margin-bottom: 0;
  }
}

@media (min-width: $screen-sm-min) {
    .bb-page-summary-left {
        display: flex;
        align-items: stretch;
    }

    .bb-page-summary-image {
        width: $bb-avatar-width + $margin-double;
        flex: 0 0 $bb-avatar-width + $margin-double;
    }
}

@media (max-width: $screen-xs-max) {
    .bb-page-summary-image {
        width: $bb-avatar-width;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $margin;

    }

    .bb-page-summary-key-info {
        margin-top: $margin-double;
    }
}
