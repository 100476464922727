@-moz-keyframes bb-spin-frames {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
    }
}

@-webkit-keyframes bb-spin-frames {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
    }
}

@-o-keyframes bb-spin-frames {
    0% {
        -o-transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(359deg);
    }
}

@-ms-keyframes bb-spin-frames {
    0% {
        -ms-transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(359deg);
    }
}

@keyframes bb-spin-frames {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}
