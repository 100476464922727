@import "variables";
/* begin _bootstrap-variable-defaults */
/* These are bootstrap-specific variables that will be set here to be inhereted by bootstrap*/


$zindex-dropdown: $z-index-flyout + 1;

$zindex-modal: $bb-zindex-dropdown-append-to-body - 1;
$border-color: $sky-border-color-neutral-medium;

$icon-font-path: 'sky/bootstrap/fonts/' !default;

$nav-link-padding: 0px 5px !default;
$alert-padding: 10px !default;

$link-color: $bb-color-blue;

$modal-inner-padding: $padding !default;
$modal-title-padding: $padding-plus-half !default;

$font-family-sans-serif: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;

$font-family-serif: BenchNine, Trebuchet, serif !default;
$font-family-base: $font-family-sans-serif !default;

/* end _bootstrap-variable-defaults */

/* Font Awesome defaults */
$fa-font-path: 'sky/fontawesome/fonts' !default;
